import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let PublicPageTitle = class PublicPageTitle extends Vue {
    mounted() { }
};
__decorate([
    Prop()
], PublicPageTitle.prototype, "title", void 0);
__decorate([
    Prop()
], PublicPageTitle.prototype, "subtitle", void 0);
PublicPageTitle = __decorate([
    Component
], PublicPageTitle);
export default PublicPageTitle;
