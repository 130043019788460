import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let TextInput = class TextInput extends Vue {
    mounted() { }
};
__decorate([
    Prop()
], TextInput.prototype, "value", void 0);
__decorate([
    Prop()
], TextInput.prototype, "id", void 0);
__decorate([
    Prop()
], TextInput.prototype, "label", void 0);
__decorate([
    Prop()
], TextInput.prototype, "placeholder", void 0);
__decorate([
    Prop()
], TextInput.prototype, "error", void 0);
__decorate([
    Prop({ default: false })
], TextInput.prototype, "required", void 0);
__decorate([
    Prop({ default: false })
], TextInput.prototype, "readonly", void 0);
TextInput = __decorate([
    Component
], TextInput);
export default TextInput;
