import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import Btn from '@/components/Button/Btn.vue';
import FormWrap from '@/components/Elements/FormWrap.vue';
import PublicPageTitle from '@/components/Elements/PublicPageTitle.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import PasswordInput from '@/components/FormInputs/PasswordInput/PasswordInput.vue';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            email: '',
            password: '',
        };
        this.errors = {
            email: [],
            password: [],
        };
    }
    async onEmailChange() {
        this.errors.email = [];
    }
    async onPasswordChange() {
        this.errors.password = [];
    }
    mounted() {
        if (this.$store.getters['auth/loggedIn']) {
            this.$router.push('my-village');
        }
    }
    async submitLogin(e) {
        try {
            await this.$store.dispatch('auth/login', this.form);
            const profile = this.$store.getters['user/getProfile'];
            this.$store.dispatch('user/setOnboarding', profile.onboarding);
            if (profile.onboarding) {
                // Redirect the user if there has been a redirect query param defined
                const redirectTo = this.$route.query.redirect ? this.$route.query.redirect : 'my-village';
                await this.$router.push(redirectTo);
            }
            else {
                await this.$router.push('onboarding');
            }
        }
        catch (e) {
            this.errors = e.data && e.data.messages ? e.data.messages : [];
        }
    }
};
__decorate([
    Watch('form.email')
], Login.prototype, "onEmailChange", null);
__decorate([
    Watch('form.password')
], Login.prototype, "onPasswordChange", null);
Login = __decorate([
    Component({
        components: { Btn, FormWrap, PublicPageTitle, TextInput, PasswordInput },
        metaInfo() {
            return {
                title: 'Africa Geographic | Login',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic Login',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/login` }],
            };
        },
    })
], Login);
export default Login;
