import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let PasswordInput = class PasswordInput extends Vue {
    constructor() {
        super(...arguments);
        this.passwordType = 'password';
    }
    mounted() { }
};
__decorate([
    Prop()
], PasswordInput.prototype, "value", void 0);
__decorate([
    Prop()
], PasswordInput.prototype, "id", void 0);
__decorate([
    Prop()
], PasswordInput.prototype, "label", void 0);
__decorate([
    Prop()
], PasswordInput.prototype, "error", void 0);
PasswordInput = __decorate([
    Component
], PasswordInput);
export default PasswordInput;
